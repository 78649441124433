.checkbox,
.radio {
  line-height: var(--line-height-tight);
  display: flex;
  align-items: center;
}

.form__group {
  display: grid;
  gap: var(--space-xxs);
  // flex-direction: column;

  &.radio_buttons,
  &.check_boxes {
    gap: var(--space-xs);
  }

  &.form__group--quiz {
    gap: var(--space-md);
  }

  .upload-preview {
    transform: translate(0);
    img {
      width: 40px;
      height: 40px;
      position: absolute;
      left: auto;
      right: 8px;
      top: -49px;
    }
  }
  .upload-preview--wide {
    @extend .upload-preview;
    img {
      width: 75px;
    }
  }
}

.form__label {
  cursor: pointer;
  display: block;
  font-size: var(--text-xs);
  font-weight: 500;
  color: var(--color-neutral-dark);
}

.checkbox {
  .form__label {
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
}

input[type=radio],
input[type=checkbox] {
  appearance: none;
  border: none;
  content: none;
  margin: 0;
  outline: none;
  position: relative;
  width: auto;
  height: auto;

  &:checked {
    appearance: none;
    border: none;
    content: none;
    outline: none;
    padding: 0;
  }

  &::before {
    display: block;
    margin-right: var(--space-xxs);
    background-color: var(--color-white);
    border: var(--border-width) solid var(--color-neutral-base);
    width: var(--space-unit);
    height: var(--space-unit);
  }

  &.radio--quiz::before,
  &.checkbox--quiz::before {
    margin-right: var(--space-xs);
    width: var(--space-lg);
    height: var(--space-lg);
    border: var(--border-width) solid var(--color-primary-dark);

    @media (max-width: $breakpoint-sm) {
      height: var(--button-height-mobile);
      width: var(--button-height-mobile);
    }
  }
}

input[type=radio] {
  &::before,
  &::after {
    content: '';
    border-radius: var(--radius-max);
  }
  &:checked::before {
    border-color: var(--color-primary-dark);
  }
  &:checked::after {
    background-color: var(--color-primary-dark);
    position: absolute;
    top: calc( var(--space-xs) * 0.5);
    left: calc( var(--space-xs) * 0.5);
    width: var(--space-xs);
    height: var(--space-xs);
  }
  &.radio--quiz:checked + label {
    color: var(--color-primary-dark);
  }
  &.radio--quiz:checked::before {
    border-width: calc(4 * var(--border-width));

    @media (max-width: $breakpoint-sm) {
      border-width: calc(6 * var(--border-width));
    }
  }
  &.radio--quiz:checked::after {
    display: none;
  }
}

input[type=checkbox] {
  &::before,
  &::after {
    content: '';
    border-radius: var(--radius-xs);
  }
  &:checked::before {
    border-color: var(--color-primary-dark);
  }
  &:checked::after {
    position: absolute;
    top: 3.5px;
    left: 6px;
    width: calc( var(--space-xs) * 0.5);
    height: var(--space-xs);
    border-color: var(--color-primary-dark);
    border-style: solid;
    border-width: 0 var(--border-width) var(--border-width) 0;
    border-radius: 0;
    transform: rotate(35deg);
  }
  &.checkbox--quiz:checked + label {
    color: var(--color-primary-dark);
  }
  &.checkbox--quiz:checked::before {
    background-color: var(--color-primary-dark);
  }
  &.checkbox--quiz:checked::after {
    border-color: var(--color-white);
    height: var(--space-md);
    width: var(--space-xs);
    left: 12px;

    @media (max-width: $breakpoint-sm) {
      left: 18px;
      top: 10px;
    }
  }

}

select,
trix-editor,
textarea,
input[type=text],
input[type=email],
input[type=tel],
input[type=password],
input[type=url],
input[type=phone],
input[type=number],
input[type=date],
input[type=search],
input[type=datetime-local],
.ts-input,
.ts-input > input {
  appearance: none;
  border-radius: var(--radius-sm);
  border: none;
  box-shadow: var(--neutral-input-border-base);
  background-color: var(--color-white);
  color: var(--color-neutral-dark);
  font-size: var(--text-sm);
  font-weight: 400;
  padding: var(--space-sm);
  width: 100%;

  // This rule breaks Trix auto-resize feature on mobile.
  &:not(trix-editor) {
    @media (max-width: $breakpoint-sm) {
      height: var(--button-height-mobile);
    }
  }

  &:focus-within {
    box-shadow: var(--neutral-input-border-focus);
    outline: 0;
  }
}

.message-textarea {
  position: relative;

  trix-editor {
    // Not sure if this is controlled elsewhere
    min-height: 10em;
    // Fragile, but unsure of a better way right now
    padding-bottom: 64px;
  }
}

select {
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI5IDE0IiB3aWR0aD0iMjkiPjxwYXRoIGZpbGw9IiNkMWQxZDEiIGQ9Ik05LjM3NzI3IDMuNjI1bDUuMDgxNTQgNi45MzUyM0wxOS41NDAzNiAzLjYyNSIvPjwvc3ZnPgo=');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 30px 15px;
  color: var(--color-neutral-darker);
  max-width: min-content;
  min-width: 10rem;
  padding-right: var(--space-lg);
  width: auto;
}

input {
  &:disabled {
    + label,
    &::before {
      // Hacky, but no good way to beat the specificity game
      color: var(--color-disabled) !important;
      cursor: not-allowed !important;
    }
  }
}

select[disabled] {
  cursor: not-allowed !important;
}

.form__error-summary {
  background-color: var(--color-danger-lighter);
  border-radius: var(--radius);
  border: 1px solid var(--color-danger-light);
  color: var(--color-danger-text);
  margin-bottom: var(--space-lg);
  padding: var(--space-lg);

  h2 {
    font-size: var(--text-md);
  }
  ul {
    margin-bottom: 0;
  }
}

.form__input--error {
  input:not([type=radio]):not([type=checkbox]), select, textarea, trix-editor {
    box-shadow: var(--color-error-border);
  }
}

.form__error {
  color: var(--color-danger-dark);
  font-size: var(--text-xs);
  padding: var(--space-xxs) var(--space-sm);

  &:before {
    font-family: var(--font-icons);
    content: 'error';
    margin-right: var(--space-xxs);
    float: left;
    margin-top: -1px;
  }
}

.form__input--small {
  width: 6rem;
}

.form__input--date {
  width: 10rem;
}

.form__hint {
  padding: var(--space-xxs) var(--space-sm);
  font-size: var(--text-xs);
  font-style: italic;
  display: block;
}

form.--disabled {
  color: var(--color-disabled);
  button[type="submit"] {
    @extend .btn, :disabled;
  }
}

.hidden-label  {
  label {
    visibility: hidden;
    height: 0;
  }
}
