// The following can be used to test the design
// div class="direct-upload-container"
//   .flex.justify-between.margin-bottom-sm
//     div class="direct-upload__filename" you-image.png
//     div class="direct-upload__percent" id="direct-upload-percent-1" 30%
//   div id="direct-upload-1" class="direct-upload direct-upload--pending"
//     div id="direct-upload-progress-1" class="direct-upload__progress" style="width: 30%"


.direct-upload {
  display: inline-block;
  position: relative;
  padding: var(--space-xs);
  border: 1px solid var(--neutral-layer-1-border);
  border-radius: var(--radius-lg);
  font-size: 11px;
  line-height: 5px;
  width: 100%;
}

.direct-upload--pending {
  opacity: 0.6;
}

.direct-upload__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.4;
  background: var(--color-primary);
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
  border-radius: var(--radius-lg);
}

.direct-upload--complete .direct-upload__progress {
  opacity: 0.8;
}

.direct-upload--error {
  border-color: var(--color-danger-light);
}

input[type=file][data-direct-upload-url][disabled] {
  display: none;
  & + .upload-preview>img {
    display: none;
  }
  & + .upload-preview--wide>img {
    display: none;
  }
}
