.word-count {
  color: var(--color-incomplete);
  font-size: var(--text-sm);
  pointer-events: none;
  text-align: center;

  &:before {
    content: 'warning';
    font-family: var(--font-icons);
    line-height: 1.2;
    margin-right: var(--space-xxs);
    vertical-align: text-top;
  }
}

.word-count--reached {
  color: var(--color-complete);

  &:before {
    content: 'check_circle';
    font-family: var(--font-icons);
    line-height: 1.2;
    margin-right: var(--space-xxs);
    vertical-align: text-top;
  }
}
