.modal {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  /*Prevent Chrome on Windows from adding a focus outline. For details, see*/
  /*https://github.com/twbs/bootstrap/pull/10951.*/
  outline: 0;
  /*We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  See also https://github.com/twbs/bootstrap/issues/17695*/
}

.modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background: var(--color-black);
  visibility: hidden;
  opacity: 0;
  transition: all var(--modal-transition-speed);
}

.modal__content {
  max-height: 500px;
  max-width: var(--modal-width);
  background-color: var(--color-white);
  border-radius: var(--radius);
  z-index: 12;
  transform: scale(0.7);
  opacity: 0;
  overflow-y: auto;
  transition: all var(--modal-transition-speed);

  @media(max-width: $breakpoint-xs) {
    width: 100%;
  }
}
    
.modal-section {
  padding: var(--space-md);
  color: var(--color-neutral-darker);
  background-color: var(--color-white);
}

.modal--active {
  display: flex;
  visibility: visible;

  .modal__content {
    transform: scale(1);
    opacity: 1;
  }

  .modal__backdrop {
    visibility: visible;
    opacity: 0.5;
  }
}

.modal__body {
  overflow-y: auto;
}

.modal__close {
  position: absolute;
  top: var(--space-xs);
  right: var(--space-xs);
  cursor: pointer;
}
