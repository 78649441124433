.tabs {
  display: flex;
  justify-content: flex-end;

  @media(max-width: $breakpoint-xs) {
    justify-content: flex-start;
  }
}

#content-wrapper.page-wrapper--native .tabs {
  justify-content: center;
}

.tab {
  border-bottom: 4px solid transparent;
  color: var(--color-neutral-darker);
  cursor: pointer;
  font-size: var(--text-xs);
  font-weight: 500;
  padding: var(--space-xs) var(--space-md);

  &:hover {
    border-color: var(--color-primary-light);
  }

  @media(max-width: $breakpoint-xs) {
    min-height: var(--button-height);
    padding: var(--space-xs) var(--space-sm);
  }
}

.tab--active {
  color: var(--color-primary-darker);
  border-color: var(--color-primary);

  &:hover {
    border-color: var(--color-primary);
  }
}

.tab--top-nav {
  @extend .tab;
}

.top-nav--brand-primary {
  .tab-wrapper {
    border-bottom: var(--border-width-sm) solid var(--neutral-card-border);
  }

  @media(max-width: $breakpoint-sm) {
    .tab-wrapper {
      background-color: var(--color-primary-transparent);
      border-bottom: initial;
    }

    .tab {
      color: var(--color-primary-lightest);
      padding: var(--space-sm) var(--space-md) var(--space-xs);

      &:hover {
        border-color: var(--color-white);
        color: var(--color-white);
      }
    }

    .tab--active {
      border-color: var(--color-white);
      color: var(--color-white);

      &:hover {
        border-color: var(--color-white);
      }
    }
  }
}
