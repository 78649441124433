*, ::after, ::before {
  box-sizing: border-box;
}

html {
  height: 100%;

  font-family: var(--font-primary);
  font-size: 1rem;
}

body {
  height: inherit;

  color: var(--neutral-layer-app-text);
  background: var(--neutral-layer-app-background);
}

button, input, optgroup, select, textarea {
  margin: 0;

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, pre {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--space-sm);

  font-weight: 400;
}

h1 {
  font-size: var(--text-xxxl);
  color: var(--color-primary-darker);
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: var(--line-height-tight);
}

h2 {
  font-size: var(--text-xl);
}

h3 {
  font-size: var(--text-lg);
}

h4 {
  font-size: var(--text-md);
}

h5 {
  font-size: var(--text-sm);
}

h6 {
  font-size: var(--text-xs);
}

p {
  margin-bottom: var(--space-sm);

  font-size: 1rem;
}

small {
  font-size: var(--text-sm);
}

a {
  color: var(--neutral-layer-link-color);
  text-decoration: none;
}

ol > li {
  margin-bottom: var(--space-sm);
}

.overflow-wrapper {
  @media(max-width: $breakpoint-md) {
    width: 100%;
    overflow: scroll;
  }
}

.page-wrapper {
  width: 100%;
  overflow: auto;

  // may not want or need this in the future
  // fixes content butting up against the bottom
  padding-bottom: var(--space-lg);

  // Allows space for mobile navigation UI
  @media(max-width: $breakpoint-sm) {
    &:not(.page-wrapper--native) {
      margin-bottom: var(--mobile-top-nav-height);
    }
    &.page-wrapper--native .container-top-nav > h1 {
      display: none;
    }
  }
}

%layout-width-stretch {
  width: 100%;                    /* Fallback */
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: stretch;                 /* Future official spec */
}

%layout-fullwidth-main {
  width: calc(100% - var(--sidebar-width));

  @media(max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.is-hidden {
  display: none!important;
}

.is-invisible {
  visibility: hidden;
}

@media(max-width: $breakpoint-xs) {
  .hide-xs {
    display: none;
  }
}

.dm-login-background {
  height: 100vh;
  width: 100vw;
  background: url('develop-me-login-bg.svg') repeat;
}

.dm-login-container {
  display: grid;
  gap: var(--space-lg);
  justify-items: center;
  padding: 40px 20px;
}

.login-header {
  width: 246px;
  height: 40px;
}

.org-logo {
  display: none;
  max-width: 250px;
  width: 100%;

  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}

.search-bar {
  width: 100%;
  max-width: 350px;

  .form__input {
    margin-right: var(--space-xs);
  }

  @media (max-width: $breakpoint-sm) {
    max-width: 100%;
  }
}
