:root {
  --button-height: 44px;
  --button-height-mobile: 44px;
}

button {
  background: none;
  border: 0;
  color: var(--color-black);
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0;

  @media (max-width: $breakpoint-sm) {
    height: var(--button-height-mobile);
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: var(--border-width-sm);
  border-radius: var(--radius-sm);
  cursor: pointer;
  font-size: var(--text-sm);
  line-height: 1;
  padding: var(--text-xs) var(--text-sm);
  text-decoration: none;
  -webkit-appearance: none;
  pointer-events: auto;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-disabled);
    border-color: var(--color-disabled);
    opacity: 0.4;

    &:hover {
      background-color: var(--color-disabled);
      border-color: var(--color-disabled);
    }
  }

  @media (max-width: $breakpoint-sm) {
    height: var(--button-height-mobile);
  }
}

// Primarily for button labels with icons to the left or right but
// can be used for any label that should be matched with an icon
.button--with-icon,
.label--with-icon {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--space-xxxs);
  justify-content: space-between;
  align-items: center;
}

.btn--primary {
  @extend .btn;
  background-color: var(--color-primary-darker);
  border: var(--border-width-sm) solid var(--color-primary-darker);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
  }
  &:active {
    background-color: var(--color-primary-darker);
    border-color: var(--color-primary-darker);
  }
}

.btn--secondary {
  @extend .btn;
  background-color: var(--color-white);
  border: var(--border-width-sm) solid var(--color-primary-darker);
  color: var(--color-primary-darker);

  &:hover {
    background-color: var(--color-primary-lighter);
  }
  &:active {
    background-color:  var(--color-primary-light);
  }
}

.btn--slider-controls {
  align-self: stretch;
  border-radius: 0;
  color: var(--color-slider-text);
  border: 0;
  padding: var(--space-xs) var(--space-sm);

  &:hover {
    background-color: var(--color-neutral-light);
  }

  &:active {
    background-color: var(--color-neutral-base);
  }

  &:disabled {
    background-color: inherit;

    &:hover {
      background-color: inherit;
    }
  }
}

.btn--slider {
  &-prev {
    padding-right: var(--space-md);
  }
  &-next {
    padding-left: var(--space-md);
  }
}

.btn--small {
  font-size: var(--text-sm);
  padding: var(--space-xxs);
}

.btn--link {
  border: 0;
  border-radius: 0;
  background-color: initial;
  color: var(--color-primary-darker);
  font-size: var(--text-sm);
  padding: 0;

  &.btn--danger {
    color: var(--color-danger-darker);

    &:hover {
      border-color: var(--color-danger-darkest);
    }
  }
}

.btn--danger:not(.btn--link) {
  border: var(--border-width-sm) solid var(--color-danger-darker);
  color: var(--color-danger-darker);

  &:hover {
    border-color: var(--color-danger-darkest);

    &:active {
      background-color: var(--color-danger-lighter);
    }
  }
}

.btn--slider {
  &-prev {
    padding-right: var(--space-md);
  }
  &-next {
    padding-left: var(--space-md);
  }
}

.btn--icon {
  border: none !important;
}

.btn-group {
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  &>button.btn--icon:not(:last-child) {
    padding-inline-end: 0em;
  }
}
