.messages-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
}

.messages-container + * {
  margin-top: var(--space-lg);
}

.message-wrapper {
  align-self: flex-end;
  max-width: 40rem;

  .message-textarea {
    min-width: var(--modal-width);

    @media(max-width: $breakpoint-xs) {
      min-width: auto;
      width: 80vw;
    }
  }

  .message {
    gap: var(--space-xxs) var(--space-sm);
    display: grid;
    grid-template-columns: [content-start] 1fr [content-end avatar-start] min-content [avatar-end];
    color: var(--color-white);

    a {
      color: var(--color-white);
      text-decoration: underline;
    }

    .message__content-container {
      background: var(--color-primary-dark);
      padding: var(--space-xs) var(--space-sm);
      border-radius: var(--radius) 0 var(--radius) var(--radius);
      grid-area: content;
    }
  }

  &--alt {
    align-self: flex-start;

    .message {
      grid-template-columns: [avatar-start] min-content [avatar-end content-start] 1fr [content-end];

      .message__content-container {
        background: var(--color-secondary-dark);
        border-radius: 0 var(--radius) var(--radius) var(--radius);
      }

      .message__header {
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
}

.message__header {
  display: flex;
  justify-content: flex-end;
  grid-column-start: content;
  color: var(--color-neutral-dark);
  font-size: var(--text-sm);
  text-align: right;
}

.avatar {
  grid-area: avatar;
}
