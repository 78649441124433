:root {
  --color-white: hsl(0, 100%, 100%);
  --color-black: hsl(0, 0%, 0%);

  --color-primary-h: 178;
  --color-primary-s: 47%;
  --color-primary-l: 63%;

  --color-secondary-h: 217;
  --color-secondary-s: 34%;
  --color-secondary-l: 33%;

  --color-neutral-h: 217;
  --color-neutral-s: 0%;
  --color-neutral-l: 94%;

  --color-green-h: 140;
  --color-green-s: 49%;
  --color-green-l: 54%;

  --color-blue-h: 219;
  --color-blue-s: 93%;
  --color-blue-l: 58%;

  --color-orange-h: 33;
  --color-orange-s: 92%;
  --color-orange-l: 47%;

  --color-red-h: 3;
  --color-red-s: 100%;
  --color-red-l: 55%;

// For advanced HSL color functions go here
// https://codesalad.dev/blog/color-manipulation-with-css-variables-and-hsl-16#darken-to-black

  // Required for postcss-loader to parse calc properly. e.g. calc(var(--panel-width) * -1) will break
  // but calc(var(--panel-width) * var(--minus-one))
  --minus-one: -1;

  // Numbered color weights (similar to font weight)

  --color-primary-50:    hsl(var(--color-primary-h), var(--color-primary-s), 98%); // Lightest
  --color-primary-100:   hsl(var(--color-primary-h), var(--color-primary-s), 92%); // Lighter
  --color-primary-200:   hsl(var(--color-primary-h), var(--color-primary-s), 82%); // Light
  --color-primary-300:   hsl(var(--color-primary-h), var(--color-primary-s), 72%);
  --color-primary-400:   hsl(var(--color-primary-h), var(--color-primary-s), 62%); // Base
  --color-primary-500:   hsl(var(--color-primary-h), var(--color-primary-s), 54%);
  --color-primary-600:   hsl(var(--color-primary-h), var(--color-primary-s), 44%);
  --color-primary-700:   hsl(var(--color-primary-h), var(--color-primary-s), 32%); // Darker
  --color-primary-800:   hsl(var(--color-primary-h), var(--color-primary-s), 24%);
  --color-primary-900:   hsl(var(--color-primary-h), var(--color-primary-s), 16%); // Darkest

  --color-secondary-50:  hsl(var(--color-secondary-h), var(--color-secondary-s), 98%);
  --color-secondary-100: hsl(var(--color-secondary-h), var(--color-secondary-s), 92%);
  --color-secondary-200: hsl(var(--color-secondary-h), var(--color-secondary-s), 82%);
  --color-secondary-300: hsl(var(--color-secondary-h), var(--color-secondary-s), 72%);
  --color-secondary-400: hsl(var(--color-secondary-h), var(--color-secondary-s), 62%);
  --color-secondary-500: hsl(var(--color-secondary-h), var(--color-secondary-s), 54%);
  --color-secondary-600: hsl(var(--color-secondary-h), var(--color-secondary-s), 44%);
  --color-secondary-700: hsl(var(--color-secondary-h), var(--color-secondary-s), 32%);
  --color-secondary-800: hsl(var(--color-secondary-h), var(--color-secondary-s), 24%);
  --color-secondary-900: hsl(var(--color-secondary-h), var(--color-secondary-s), 16%);

  --color-neutral-50:    hsl(var(--color-neutral-h), var(--color-neutral-s), 98%); // Lightest
  --color-neutral-75:    hsl(var(--color-neutral-h), var(--color-neutral-s), 94%);
  --color-neutral-100:   hsl(var(--color-neutral-h), var(--color-neutral-s), 88%); // Lighter
  --color-neutral-200:   hsl(var(--color-neutral-h), var(--color-neutral-s), 78%); // Light
  --color-neutral-300:   hsl(var(--color-neutral-h), var(--color-neutral-s), 68%);
  --color-neutral-400:   hsl(var(--color-neutral-h), var(--color-neutral-s), 60%); // Base
  --color-neutral-500:   hsl(var(--color-neutral-h), var(--color-neutral-s), 44%);
  --color-neutral-600:   hsl(var(--color-neutral-h), var(--color-neutral-s), 38%); // Dark
  --color-neutral-700:   hsl(var(--color-neutral-h), var(--color-neutral-s), 28%);
  --color-neutral-800:   hsl(var(--color-neutral-h), var(--color-neutral-s), 18%); // Darker
  --color-neutral-900:   hsl(var(--color-neutral-h), var(--color-neutral-s), 08%); // Darkest

  --color-green-50:  hsl(var(--color-green-h), var(--color-green-s), 95%); // Lightest
  --color-green-100: hsl(var(--color-green-h), var(--color-green-s), 84%);
  --color-green-200: hsl(var(--color-green-h), var(--color-green-s), 74%); // Lighter
  --color-green-300: hsl(var(--color-green-h), var(--color-green-s), 64%); // Light
  --color-green-400: hsl(var(--color-green-h), var(--color-green-s), 54%); // Base
  --color-green-500: hsl(var(--color-green-h), var(--color-green-s), 43%); // Dark
  --color-green-600: hsl(var(--color-green-h), var(--color-green-s), 34%); // Darker
  --color-green-700: hsl(var(--color-green-h), var(--color-green-s), 24%);
  --color-green-800: hsl(var(--color-green-h), var(--color-green-s), 20%);
  --color-green-900: hsl(var(--color-green-h), var(--color-green-s), 14%); // Darkest

  --color-orange-50:  hsl(var(--color-orange-h), var(--color-orange-s), 97%); // Lightest
  --color-orange-100: hsl(var(--color-orange-h), var(--color-orange-s), 87%);
  --color-orange-200: hsl(var(--color-orange-h), var(--color-orange-s), 77%); // Lighter
  --color-orange-300: hsl(var(--color-orange-h), var(--color-orange-s), 67%); // Light
  --color-orange-400: hsl(var(--color-orange-h), var(--color-orange-s), 57%); // Base
  --color-orange-500: hsl(var(--color-orange-h), var(--color-orange-s), 45%); // Dark
  --color-orange-600: hsl(var(--color-orange-h), var(--color-orange-s), 37%); // Darker
  --color-orange-700: hsl(var(--color-orange-h), var(--color-orange-s), 27%);
  --color-orange-800: hsl(var(--color-orange-h), var(--color-orange-s), 17%);
  --color-orange-900: hsl(var(--color-orange-h), var(--color-orange-s), 13%); // Darkest

  --color-red-50:  hsl(var(--color-red-h), var(--color-red-s), 95%); // Lightest
  --color-red-100: hsl(var(--color-red-h), var(--color-red-s), 85%);
  --color-red-200: hsl(var(--color-red-h), var(--color-red-s), 75%); // Lighter
  --color-red-300: hsl(var(--color-red-h), var(--color-red-s), 65%); // Light
  --color-red-400: hsl(var(--color-red-h), var(--color-red-s), 55%); // Base
  --color-red-500: hsl(var(--color-red-h), var(--color-red-s), 45%); // Dark
  --color-red-600: hsl(var(--color-red-h), var(--color-red-s), 35%); // Darker
  --color-red-700: hsl(var(--color-red-h), var(--color-red-s), 25%);
  --color-red-800: hsl(var(--color-red-h), var(--color-red-s), 15%);
  --color-red-900: hsl(var(--color-red-h), var(--color-red-s), 10%); // Darkest

   --color-yellow-50: hsl(48, 100%, 99%); // Lightest
  --color-yellow-100: hsl(48, 100%, 89%); // Lighter
  --color-yellow-200: hsl(48, 100%, 79%);
  --color-yellow-300: hsl(48, 100%, 69%); // Light - AA compatible under --color-neutral-darker
  --color-yellow-400: hsl(48, 100%, 59%); // Base
  --color-yellow-500: hsl(48, 100%, 49%);
  --color-yellow-600: hsl(48, 100%, 39%);
  --color-yellow-700: hsl(48, 100%, 29%); // Dark
  --color-yellow-800: hsl(48, 100%, 19%); // Darker
  --color-yellow-900: hsl(48, 100%, 09%); // Darkest

  // Primary Colors (normal usage)
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-lightest:   var(--color-primary-50);
  --color-primary-lighter:    var(--color-primary-100);
  --color-primary-light:      var(--color-primary-200);
  --color-primary-base:       var(--color-primary-400);
  --color-primary-dark:       var(--color-primary-600);
  --color-primary-darker:     var(--color-primary-700);
  --color-primary-darkest:    var(--color-primary-900);

  // Secondary Colors (normal usage)
  --color-secondary: hsl(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l));
  --color-secondary-lightest: var(--color-secondary-50);
  --color-secondary-lighter:  var(--color-secondary-100);
  --color-secondary-light:    var(--color-secondary-200);
  --color-secondary-base:     var(--color-secondary-400);
  --color-secondary-dark:     var(--color-secondary-500);
  --color-secondary-darker:   var(--color-secondary-800);
  --color-secondary-darkest:  var(--color-secondary-900);

  // Neutral Colors (normal usage)
  --color-neutral-lightest:   var(--color-neutral-50);
  --color-neutral-lighter:    var(--color-neutral-100);
  --color-neutral-light:      var(--color-neutral-200);
  --color-neutral-base:       var(--color-neutral-400);
  --color-neutral-dark:       var(--color-neutral-500);
  --color-neutral-darker:     var(--color-neutral-800);
  --color-neutral-darkest:    var(--color-neutral-900);

  // Neutral Colors (custom)
  --neutral-layer-app-background: hsl(var(--color-primary-h), 24%, 97%);
  --neutral-layer-sidebar-background: var(--color-white);
  --neutral-layer-header-background: var(--color-primary-lightest);
  --neutral-layer-footer-background: var(--color-primary-lightest);
  --neutral-card-background: var(--color-neutral-50);
  --neutral-card-border: hsl(var(--color-neutral-h), var(--color-neutral-s), 89%);

  // Success
  --color-success-lightest: var(--color-green-50);
  --color-success-lighter:  var(--color-green-200);
  --color-success-light:    var(--color-green-300);
  --color-success-base:     var(--color-green-400);
  --color-success-dark:     var(--color-green-500);
  --color-success-darker:   var(--color-green-600);
  --color-success-darkest:  var(--color-green-900);
  --color-success-text:     var(--color-neutral-darker);

  // Info
  --color-info-lightest: var(--color-neutral-50);
  --color-info-lighter:  var(--color-neutral-200);
  --color-info-light:    var(--color-neutral-300);
  --color-info-base:     var(--color-neutral-400);
  --color-info-dark:     var(--color-neutral-500);
  --color-info-darker:   var(--color-neutral-600);
  --color-info-darkest:  var(--color-neutral-900);
  --color-info-text:     var(--color-neutral-darker);

  // Warning
  --color-warning-lightest: var(--color-orange-50);
  --color-warning-lighter:  var(--color-orange-200);
  --color-warning-light:    var(--color-orange-300);
  --color-warning-base:     var(--color-orange-400);
  --color-warning-dark:     var(--color-orange-500);
  --color-warning-darker:   var(--color-orange-600);
  --color-warning-darkest:  var(--color-orange-900);
  --color-warning-text:     var(--color-neutral-darker);

  // Danger
  --color-danger-lightest: var(--color-red-50);
  --color-danger-lighter:  var(--color-red-200);
  --color-danger-light:    var(--color-red-300);
  --color-danger-base:     var(--color-red-400);
  --color-danger-dark:     var(--color-red-500);
  --color-danger-darker:   var(--color-red-600);
  --color-danger-darkest:  var(--color-red-900);
  --color-danger-text:     var(--color-neutral-darker);

  // Box-shadow border
  --neutral-input-border-base: inset 0px 0px 0px 1px var(--color-neutral-base);
  --neutral-input-border-focus: inset 0px 0px 0px 2px var(--color-neutral-dark);

  --neutral-border: inset 0px 0px 0px 1px var(--neutral-card-border);
  --neutral-border-right: var(--border-width-sm) 0px 0px var(--neutral-card-border);
  --neutral-border-top: 0px calc(var(--border-width-sm) * var(--minus-one)) 0px var(--neutral-card-border);
  --neutral-border-bottom: 0px var(--border-width-sm) 0px var(--neutral-card-border);
  --neutral-border-left: calc(var(--border-width-sm) * var(--minus-one)) 0px 0px var(--neutral-card-border);

  --color-primary-border: inset 0px 0px 0px 1px var(--color-primary-light);
  --color-error-border: inset 0px 0px 0px 2px var(--color-danger-dark);

  --warning-border: inset 0px 0px 0px 1px var(--color-orange-500);

  // Alerts
  --color-success: var(--color-green-700);

  // Completeness
  --color-incomplete: var(--color-danger-dark);
  --color-complete: var(--color-green-700);


  --neutral-layer-app-text: var(--color-black);
  --neutral-layer-link-color: var(--color-primary-darker);
  --neutral-layer-app-clickable-background: linear-gradient(0deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.98)), var(--color-primary);

  --neutral-layer-sidebar-text:         var(--color-neutral-800);
  --neutral-layer-sidebar-hover:        var(--color-neutral-400);
  --neutral-layer-sidebar-hover-active: var(--color-neutral-50);
  --neutral-layer-sidebar-shadow: 1px 0px 0px var(--color-neutral-lighter);

  --neutral-layer-1-background:         var(--color-neutral-50);
  --neutral-layer-1-text:               var(--color-neutral-800);
  --neutral-layer-1-border:             var(--color-neutral-400);

  --neutral-layer-2-background:         var(--color-neutral-300);
  --neutral-layer-2-text:               var(--color-neutral-800);
  --neutral-layer-2-border:             var(--color-neutral-500);

  --neutral-layer-3-background:         var(--color-neutral-400);
  --neutral-layer-3-text:               var(--color-neutral-800);
  --neutral-layer-3-border:             var(--color-neutral-600);

  --neutral-layer-4-background:         var(--color-neutral-500);
  --neutral-layer-4-text:               var(--color-neutral-100);
  --neutral-layer-4-border:             var(--color-neutral-200);

  --neutral-layer-card:                 var(--color-white);
  --neutral-layer-card-border:          var(--color-neutral-200);
  --neutral-layer-card-container:       var(--color-neutral-200);
  --neutral-layer-floating:             var(--color-neutral-200);

  --branded-layer-card-border: hsl(
    var(--color-primary-h),
    var(--color-primary-s),
    calc(var(--color-primary-l) + (100% - var(--color-primary-l)) * 0.76)
  );

  --color-disabled: var(--color-neutral-base);


  // Border Radius
  --radius-xs:     4px;
  --radius-sm:     8px;
  --radius-md:    12px;
  --radius:       16px;
  --radius-lg:    24px;
  --radius-max:   50%;

  // Border Width
  --border-width-sm: 1px;
  --border-width:    2px;

  // Shadows
  --shadow-elevation-xs: 0 2px 4px hsla(255, 0%, 0%, .1);
  --shadow-elevation-sm: 0 4px 8px hsla(255, 0%, 0%, .15);
  --shadow-elevation-md: 0 6px 12px hsla(255, 0%, 0%, .175);
  --shadow-elevation-lg: 0 12px 24px hsla(255, 0%, 0%, .175);
  --shadow-elevation-xl: 0 24px 48px hsla(255, 0%, 0%, .175);
  --shadow-primary-elevation-xs: 0 2px 4px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .1);
  --shadow-primary-elevation-sm: 0 4px 8px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .125);
  --shadow-primary-elevation-md: 0 1px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .10),
                                 0 3px 6px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .10),
                                 0 6px 12px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .10);
  --shadow-primary-elevation-lg: 0 12px 24px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .175);

  // Shadows with Border
  --shadow-primary-elevation-md-outline: inset 0px 0px 0px 1px var(--color-primary-base),
                                         var(--shadow-primary-elevation-md);



  // Font Families
  --font-primary:   'Aktifo A', sans-serif;
  --font-secondary: 'Aktifo A', sans-serif;
  --font-icons:     'Material Icons';

  // Text Ratios
  // Visit https://codyhouse.co/ds/globals/typography to play around with different text sizes
  --text-base-size:   16px;
  --text-scale-ratio: 1.15;
  --text-unit: var(--text-base-size);

  --text-xxs: calc((var(--text-xs) / var(--text-scale-ratio)) / (var(--text-scale-ratio) * 1.1)); // ~10px
  --text-xs: calc(calc(var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio)); // ~12px
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio)); // ~14px
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)); // ~18px
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio)); // ~21px
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio)); // ~24px
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio)); // ~28px
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio)); // ~32px
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio)); // ~37px

  // Start of line height variables
  --line-height-none: 0;
  --line-height-tightest: 1;
  --line-height-tighter: 1.15;
  --line-height-tight: 1.3;
  --line-height-base: 1.5;
  --line-height-loose: 1.6;

  // Panel
  --panel-width: 40%;
  --panel-transition-speed: 400ms;

  // Modals
  --modal-width: 564px;
  --modal-transition-speed: 300ms;

  // Spacing
  // Visit https://codyhouse.co/ds/globals/spacing to play around with different ratios
  --space-unit: 16px;
  --space-base: calc(1 * var(--space-unit)); // 16px

  --space-xxxxs: calc(0.125 * var(--space-unit)); // 2px
  --space-xxxs: calc(0.25 * var(--space-unit)); // 4px
  --space-xxs: calc(0.375 * var(--space-unit)); // 6px
  --space-xs: calc(0.5 * var(--space-unit)); // 8px
  --space-sm: calc(0.75 * var(--space-unit)); // 12px
  --space-md: calc(1.25 * var(--space-unit)); // 20px
  --space-lg: calc(2 * var(--space-unit)); // 32px
  --space-xl: calc(3.25 * var(--space-unit)); // 52px
  --space-xxl: calc(5.25 * var(--space-unit)); // 84px
  --space-xxxl: calc(8.5 * var(--space-unit)); // 136px
  --space-xxxxl: calc(13.75 * var(--space-unit)); // 220px
  --component-padding: var(--space-base) var(--space-md); // 16px/20px

  // Icon Sizes
  --icon-size-sm: 18px;
  --icon-size-md: 24px;
  --icon-size-lg: 36px;
  --icon-size-xl: 48px;

  // CSS Transitions
  --transition-ease-out-faster: all 0.1s ease-out;
  --transition-ease-out-fast: all 0.2s ease-out;

  --background-primary-gradient: linear-gradient(hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 1), hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0) 64%);
  --color-primary-transparent: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .88);
}

// Breakpoints - SCSS variables until support is available for custom properties
$breakpoint-xs: 32rem; // ~512px phone
$breakpoint-sm: 48rem; // ~768px vertical ipad
$breakpoint-md: 64rem; // ~1024px landscape ipad
$breakpoint-lg: 80rem; // ~1280px small laptop
$breakpoint-xl: 90rem; // ~1440px big laptop

:root {
  @media(max-width: $breakpoint-xs) {
    --text-xxxl: var(--text-xxl);
  }
}
