.tag {
  font-size: var(--text-sm);
  font-weight: 400;
  color: var(--color-neutral-dark);
}

.tag--font-md {
  font-size: var(--text-md)
}

.tag--complete {
  color: var(--color-success);

  &:before {
    font-family: var(--font-icons);
    content: 'check_circle';
    margin-right: var(--space-xxs);
    float: left;
    margin-top: -1px;
  }
}

.tag--caution {
  color: var(--color-neutral-dark);

  &:before {
    font-family: var(--font-icons);
    content: 'warning';
    margin-right: var(--space-xxs);
    float: left;
    margin-top: -1px;
  }
}

.tag--danger {
  color: var(--color-danger-dark);

  &:before {
    font-family: var(--font-icons);
    content: 'warning';
    margin-right: var(--space-xxs);
    float: left;
    margin-top: -1px;
  }
}

.tag--gray-outline {
  background: none;
  border: 3px solid var(--color-neutral-400);
  color: var(--neutral-layer-app-text);
  border-radius: var(--radius-sm);
  padding: var(--space-xxs);
}

.container-course-meta {
  gap: var(--space-sm);

  .tag {
    box-shadow: var(--neutral-border);
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--radius-sm);
    background: var(--color-white);
    margin-right: var(--space-xs);
  }
  .tag__data {
    color: var(--color-neutral-darker);
    font-weight: 600;
  }
}
