// Look up icons at: https://material.io/resources/icons/
@import 'material-icons/iconfont/material-icons.scss';

.material-icons {
  font-size: 1.3em;
  line-height: 0.9em;
  max-width: 100%;
}

// Icon Sizes
.icon--sm {
  font-size: var(--icon-size-sm);
}

.icon--md {
  font-size: var(--icon-size-md);
}

.icon--lg {
  font-size: var(--icon-size-lg);
}

.icon--xl {
  font-size: var(--icon-size-xl);
}

.icon--space {
  &-left {
    margin-left: var(--space-xs);
  }
  &-right {
    margin-right: var(--space-xs);
  }
}
