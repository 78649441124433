trix-editor {
  figure[data-trix-content-type^=video] {
    img {
      display: none;
    }
    &:not(.attachment--file) figcaption {
      padding: var(--space-sm) var(--space-sm);
      border: 1px solid var(--color-neutral-base);
      border-radius: var(--radius-sm);
      margin: 0 auto;
      max-width: 360px;
    }
  }
}

// Hide some buttons for now for less clutter in the app.
// Can add them back in as needed
@media(max-width: $breakpoint-md) {
  trix-toolbar .trix-button-row {
    justify-content: unset;
  }
}

.trix-button--icon-increase-nesting-level,
.trix-button--icon-decrease-nesting-level,
.trix-button-group--history-tools,
.trix-button--icon-heading-1,
.trix-button--icon-quote,
.trix-button--icon-code,
.trix-button--icon-undo {
  display: none!important;
}
