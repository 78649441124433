.top-nav {
  background-color: var(--neutral-layer-header-background);
  margin-bottom: var(--space-md);

  h1 {
    margin: 0;
    letter-spacing: -.025rem;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    background-color: var(--neutral-layer-app-background);
  }

}

.top-nav--chat {
  @extend %layout-fullwidth-main;

  position: fixed;
  top: 0;
  z-index: 1;
}

.top-nav__content {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);

  &.container-tabs {
    padding-bottom: 0;
  }

  @media(max-width: $breakpoint-sm) {
    padding-top: 0;
    padding-bottom: var(--space-sm);
  }
}

#content-wrapper:not(.page-wrapper--native) .top-nav__content:not(.container-tabs) {
  border-bottom: var(--border-width-sm) solid var(--neutral-card-border);
}

.top-nav__banner {
  border-bottom: var(--border-width-sm) solid var(--neutral-card-border);
  padding: var(--space-sm) 0;
  background: var(--color-white);
  box-shadow: none;
}

.meta__info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-self: flex-start;
  gap: var(--space-md) var(--space-xl);

  @media(max-width: $breakpoint-xs) {
    gap: var(--space-sm);
  }

  .pair {
    display: grid;
    gap: var(--space-xxxs);
    color: var(--color-neutral-dark);
  }

  .icon--sm {
    vertical-align: text-top;
  }

  .value {
    color: var(--color-neutral-darker);
    font-weight: 600;
  }

  a {
    color: var(--color-primary-dark);
    text-decoration: underline;
  }
}

.top-nav__emblem {
  height: 40px;
}

.top-nav--brand-primary {
  @media(max-width: $breakpoint-sm) {
    --breadcrumb-color: var(--color-white);
    --breadcrumb-divider: var(--color-primary-darker);

    background-color: var(--color-primary);
    position: relative;

    h1 {
      color: var(--color-white);
    }

    .top-nav__content {
      min-height: 130px;
      display: flex;
      align-items: center;
      flex-direction: row;

      &.container-tabs {
        border-bottom: none;
        padding-bottom: var(--space-md);
      }
    }
  }
}

// Not completely sure where to put this
.container-background-banner {
  @media(max-width: $breakpoint-sm) {
    position: relative;
    background: var(--background-primary-gradient),
            var(--background-banner-image) no-repeat center center,
            var(--color-primary-dark);
    background-size: cover;
  }
}

@media(max-width: $breakpoint-sm) {
  .top-nav--brand-primary {
    .top-nav__content {
      padding-top: var(--mobile-top-nav-height);
    }
  }
}

// Allows space for mobile navigation UI
@media(max-width: $breakpoint-sm) {
  .top-nav {
    &:not(.top-nav--brand-primary) {
      margin-top: var(--mobile-top-nav-height);
    }
  }
}
