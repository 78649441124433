%container {
  @extend %layout-width-stretch;

  display: grid;
  gap: var(--space-sm);
  margin: 0 auto;
  padding: 0 var(--space-lg);
  max-width: $breakpoint-md;
  position: relative;

  @media(max-width: $breakpoint-xs) {
    padding: 0 var(--space-md);
  }
}

.container {
  @extend %container;
}

.container-chat-history {
  @extend %container;
  margin-top: var(--space-xxxl);

  &--meeting {
    margin-top: var(--space-xxxxl);
  }
}

.container-top-nav {
  @extend %container;
  gap: var(--space-sm);
  user-select: none;

  .heading {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--space-xl);
    align-items: center;
  }
}

.container--sm {
  margin: 0 auto;
  padding: 0 var(--space-lg);
  max-width: $breakpoint-sm;
  position: relative;

  @media(max-width: $breakpoint-xs) {
    padding: 0 var(--space-md);
  }
}

.container-meta {
  @extend %container;
  font-size: var(--text-sm);
}

.container-meta--actions {
  @extend .container-meta;
  grid-template-columns: 1fr min-content;
}

.container-bottom-nav {
  @extend %container;
  margin-bottom: var(--space-xxl);
}

.container-bottom-message {
  @extend %container;
  margin-bottom: var(--space-xxxxl);
}

.container-login {
  @extend %container;
  max-width: $breakpoint-sm;
}

.container-sections {
  @extend %container;
  gap: var(--space-lg);
}

.container-add-message {
  @extend %layout-width-stretch;
}

.container-members {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, auto));
  gap: var(--space-lg) var(--space-md);
  justify-content: space-evenly;
}

.container-heading-content {
  display: grid;
  gap: var(--space-sm);
}

.container-split {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xs) var(--space-sm);

  @media(max-width: $breakpoint-xs) {
    grid-template-columns: auto;
  }
}

.container-cards {
  display: grid;
  gap: var(--space-sm);
}

.container-course-meta {
  display: flex;
}

.container-form-controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-sm);

  // Hacky?
  :last-child {
    margin-left: auto;
  }
}

.container-breadcrumbs-badge {
  display: flex;
  position: relative;
}

.container-form {
  display: grid;
  gap: var(--space-md);
}

.container-meeting-options {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
  }
}

.container-flashes {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 25rem;
  overflow-y: scroll;
  padding: var(--space-lg);
  pointer-events: none;
  width: max-content;
  z-index: 1;

  @media(max-width: $breakpoint-xs) {
    align-items: center;
    left: 0;
    max-width: 100%;
    width: 100%;
  }
}

.container-preferences {
  flex-direction: row;
  column-gap: var(--space-xl);

  @media(max-width: $breakpoint-xs) {
    flex-direction: column;
  }
}

.container-courses {
  @extend %container;

  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-lg);

  @media (max-width: $breakpoint-md) {
    grid-template-columns: 1fr;
  }
}

.container-progressbar {
  position: relative;
}

.container-quiz {
  display: grid;
  gap: var(--space-sm);
}

.container-image {
  display: grid;
  place-items: center;
  img {
    max-height: 100px;
  }
}