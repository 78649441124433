:root {
  --breadcrumb-divider: hsl(240, 2%, 57%);
  --breadcrumb-color: var(--color-primary-darker);

  --mobile-top-nav-height: 44px;
  --mobile-top-nav-icon-height: 18px;
  --tabbar-height: 48px;
}

.breadcrumbs {
  display: flex;

  label.menu {
    display: none;
  }
}

.breadcrumb {
  font-size: var(--text-sm);
  margin-right: var(--space-sm);

  &::after {
    color: var(--breadcrumb-divider);
    content: '>';
    margin-left: var(--space-sm);
  }

  &:last-of-type {
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: var(--breadcrumb-color);
    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb.breadcrumb--back {
  :last-of-type {
    font-weight: 400;
  }

  &::after {
    display: none;
  }
}

.breadcrumb--current {
  display: none;
}

.breadcrumbs--responsive {
  display: none;
}

.breadcrumbs ~ .container {
  margin-top: var(--space-md);
}

.breadcrumbs.breadcrumbs--responsive ~ .container {
  margin-top: var(--space-xl);
}

nav.tabbar {
  display: none;
}

@media only screen and (max-width: $breakpoint-sm) {
  .breadcrumbs {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    background-color: var(--neutral-layer-app-background);
    color: var(--color-primary);
    padding: var(--space-sm);
    width: 100%;
    height: var(--mobile-top-nav-height);
    z-index: 100;

    label.menu {
      align-self: center;
      display: none; //Hidden within this applications top nav. Refactor to remove once customer approves.
      margin: 0;
      height: var(--mobile-top-nav-icon-height);
    }
  }

  .breadcrumbs--responsive {
    display: flex;
  }

  .breadcrumbs + .container {
    margin-top: var(--space-xxl);
  }

  .breadcrumbs.breadcrumbs--responsive + .container {
    margin-top: var(--space-xxl);
  }

  .breadcrumbs.breadcrumbs--responsive + .flash {
    margin-top: 3.25rem;
  }

  .breadcrumbs + .flash + .container {
    margin-top: var(--space-md);
  }

  .breadcrumb {
    display: none;
    align-self: baseline;
  }

  .breadcrumb--back {
    display: block;
    width: 1em;
    height: 1.2em;
    a {
      visibility: hidden;
    }

    a::before {
      /* stylelint-disable font-family-no-missing-generic-family-keyword */
      font-family: 'Material Icons';
      /* stylelint-enable font-family-no-missing-generic-family-keyword */
      content: '\E5E0';
      font-size: 120%;
      visibility: visible;
      color: var(--color-primary-dark);
    }
  }
  .breadcrumb--back::after {
    content: '';
  }

  .breadcrumb--current {
    display: block;
    color: var(--color-primary-darker);
    flex-grow: 1;
    font-size: var(--text-xs);
    text-align: center;

    a {
      color: var(--neutral-layer-app-background);
    }
  }

  nav.tabbar {
    position: fixed;
    display: flex;
    width: 100%;
    background-color: var(--color-white);

    bottom: 0;

    z-index: 100;

    .sidebar-nav__item {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      flex-direction: column;
      border-radius: 0;
      font-size: var(--text-xxs);
      font-weight: 400;
      height: var(--tabbar-height);
      margin-bottom: 0;
      padding: var(--space-xs) var(--space-xs) var(--space-sm) var(--space-xs);
    }

    .sidebar-nav__item--active {
      color: var(--color-primary-darkest);
      background-color: var(--color-white);
      border: none;
      box-shadow: none;
    }

    .material-icons {
      margin-right: 0;
      margin-bottom: var(--space-xs);
    }

    label {
      display: flex;
      margin-right: 0;
    }
  }
}

.collapse {
  visibility: collapse;
}

@media (max-width: $breakpoint-sm) {
  .top-nav--brand-primary {
    .breadcrumbs {
      background-color: initial;
    }
    .breadcrumb--current {
      display: none;
    }

    .breadcrumb--back a::before {
      color: var(--color-white);
    }
  }
}
