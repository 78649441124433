.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .avatar {
    height: var(--avatar-size-xl);
    width: var(--avatar-size-xl);
  }

  .name {
    margin-top: var(--space-md);
  }

  .active {
    color: var(--color-neutral-base);
    font-size: var(--text-sm);
    margin-top: var(--space-xs);
  }
}
