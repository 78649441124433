.course-link > .card {
  cursor: not-allowed;
  border: var(--border-width) dashed var(--color-danger-light);
}
.course-link > a > .card {
  cursor: pointer;
}

.course-card-background-banner {
  position: relative;
  background: var(--background-primary-gradient),
          var(--background-banner-image) no-repeat center center,
          var(--color-primary);
  background-size: cover;
}
