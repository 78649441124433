.bottom-nav {
  @extend %layout-fullwidth-main;
  position: fixed;
  background-color: var(--neutral-layer-footer-background);
  bottom: 0;
  box-shadow: var(--neutral-border-top);
  padding: var(--space-xxxxs) 0;
}

.bottom-nav--content-container {
  @extend .container;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: var(--space-xxs);

  @media(max-width: $breakpoint-sm) {
    padding: var(--space-xxxs);
  }
}

.container.has-bottom-nav {
  margin-bottom: var(--space-xxxl);
}

.nav-container {
  display: grid;
  width: 100%;
  align-items: stretch;

  @media(max-width: $breakpoint-sm) {
    height: auto;

    .section-nav__name:not(.section-nav__complete) {
      display: none;
    }
  }
}

.section-nav__icon {
  vertical-align: text-top;
  color: var(--color-primary-darker);
}

.section-next {
  align-items: center;
}

.nav-wrapper--next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-container--split {
  grid-template-columns: 1fr 1fr;
}

.nav-wrapper--prev {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: var(--border-width-sm) solid var(--color-neutral-lighter);
}

.nav-wrapper--next {
  justify-content: flex-start;
}

.section-nav {
  display: grid;
  gap: var(--space-xxxxs);
  align-content: center;
  justify-items: flex-end;
  line-height: 1.5;
  border-radius: 0;
  min-height: var(--button-height);
  width: 100%;

  @media(max-width: $breakpoint-sm) {
    .section-nav__complete {
      color: var(--color-neutral-dark);
      font-size: var(--text-sm);
      position: relative;
      line-height: 1.5;
    }
  }

  &:hover .section-nav__header {
    color: var(--color-neutral-darker);
  }

  &:hover .section-nav__name {
    text-decoration: underline;
  }
}

.section-prev {
  align-items: end;
  justify-items: end;
  text-align: right;
  margin-right: var(--space-lg);

  @media(max-width: $breakpoint-sm) {
    margin-right: var(--space-sm);
  }
}

.section-next {
  align-items: start;
  justify-items: start;
  text-align: left;
  margin-left: var(--space-lg);

  @media(max-width: $breakpoint-sm) {
    margin-left: var(--space-sm);
  }
}

.section-nav__header {
  color: var(--color-neutral-dark);
  font-size: var(--text-sm);
  position: relative;
}

.section-nav__name {
  color: var(--color-primary-darker);
  position: relative;
  line-height: 1.2;
}

@media(max-width: $breakpoint-sm) {
  #content-wrapper:not(.page-wrapper--native) .bottom-nav {
    bottom: var(--tabbar-height);
  }
}