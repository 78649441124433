.empty {
  @extend .card--subtle;

  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-xxs);
  justify-items: center;
  color: var(--color-neutral-dark);
  text-align: center;
}

.empty__details {
  font-size: var(--text-xs);
}
