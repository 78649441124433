// to keep things consistent in the UI, we only want list item used as a
// clickable item

.list-item {
  display: block;
  display: grid;
  gap: var(--space-md);
  background-color: var(--color-white);
  border: 1px solid var(--color-primary);
  border-radius: var(--radius);
  box-shadow: var(--shadow-primary-elevation-sm);
  color: var(--color-primary-darker);
  padding: var(--component-padding);
  line-height: var(--line-height-tight);
  position: relative;
  overflow: hidden;

  transition: var(--transition-ease-out-fast);

  &.container-progressbar {
    padding-bottom: var(--space-xl);
  }

  &:hover {
    background-color: var(--color-primary-lighter);
    border: 1px solid var(--color-primary-dark);

    .list-item__status {
      background-color: var(--color-primary-light);
    }
  }

  &:active {
    background-color: var(--color-primary-lighter);
    border: 1px solid var(--color-primary-base);
    box-shadow: inset 0 1px 2px var(--color-neutral-200);
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--color-primary-base);
  }
}

.list-item-details {
  display: grid;
  row-gap: var(--space-xs);
}

.list-item__title {
  font-size: var(--text-base-size);
  font-weight: 500;
  text-transform: capitalize;
}

.list-item__subtitle {
  font-size: var(--text-xs);
  font-weight: 400;
}

.list-item__status {
  background-color: var(--color-primary-lighter);
  border-radius: var(--radius-sm);
  font-size: var(--text-xs);
  font-weight: 500;
  padding: var(--space-xs) var(--space-sm);
  transition: var(--transition-ease-out-fast);
}
