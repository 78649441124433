.notification {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: auto auto;
  background-color: var(--color-white);
  border: var(--border-width-sm) solid var(--color-neutral-base);
  border-radius: var(--radius);
  font-size: var(--text-sm);
  padding: var(--space-md);
  position: relative;

  @media(max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
    gap: var(--space-base);  
  }
}

.notification__meta {
  display: grid;
  gap: var(--space-xxs);

  @media(max-width: $breakpoint-xs) {
    gap: var(--space-xxs);
  }
}

.notification__name {
  a {
    color: var(--color-neutral-darker);
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}

.notification__date {
  color: var(--color-neutral-dark);
}

.notification--unread {
  background-color: var(--neutral-card-background);
  box-shadow: var(--neutral-border);
  border: none;
}