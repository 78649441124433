.course-item {
  background-color: var(--color-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-primary-elevation-md);
  line-height: 1.5;
  overflow: hidden;
  padding: 0;
  position: relative;

  &:hover {
    box-shadow: var(--shadow-primary-elevation-md-outline);
  }

  .main {
    background-color: var(--color-primary-dark);
    color: var(--color-white);
    font-size: var(--text-xl);
    font-weight: 600;
    padding: var(--space-lg);

    &.container-progressbar {
      padding-bottom: var(--space-xl);
    }
  }

  .heading {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    position: relative;
    gap: var(--space-md);
  }

  .progressbar .progressbar-label {
    color: var(--color-primary-lighter);
  }

  .tags {
    display: grid;
    gap: var(--space-sm);
    grid-template-columns: auto auto;
    padding: var(--space-md);
    text-align: center;
  }

  .pair {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    gap: var(--space-xxs);
    border: var(--border-width-sm) solid var(--color-primary-lighter);
    border-radius: var(--radius-sm);
    color: var(--color-primary-dark);
    font-size: var(--text-xs);
    padding: var(--space-xs);

    @media(max-width: $breakpoint-xs) {
      grid-template-columns: auto;
      gap: 0;
    }
  }

  .value {
    font-weight: 600;
  }

  &.course-item--locked {
    background-color: var(--color-neutral-lightest);
    border: var(--border-width-sm) dashed var(--color-neutral-light);
    box-shadow: none;
    color: var(--color-neutral-dark);
    font-size: var(--text-xl);
    font-weight: 400;
    padding: var(--space-lg);
  }
}
