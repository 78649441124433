.organization-header {
  padding: var(--space-sm);
  background: var(--color-primary);
  color: var(--color-primary-text);

  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);

  text-align: center;

  h3 {
    margin-bottom: 0;
  }
}
