@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Light-Oblique.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Book.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Book-Oblique.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Medium-Oblique.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-SemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-SemiBold-Oblique.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Aktifo A";
  src: url("Aktifo-A-Bold-Oblique.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Revans";
  src: url("Revans-Normal.otf") format("opentype");
  font-weight: 400;
}

.section-heading {
  color: var(--color-neutral-dark);
  margin: 0;
  font-size: var(--text-md);
  line-height: 1.5;
}

.trix-content {
  p + p {
    margin-top: var(--space-sm);
  }

}

blockquote {
  color: var(--color-neutral-dark);
  padding-left: var(--space-sm);
  margin-left: var(--space-md);
  border-left: 4px solid var(--color-neutral-lighter);
}

.card-section--subtle {
  h4 {
    font-size: inherit;
  }
}

.notification-header {
  color: var(--color-neutral-darker);
  font-size: var(--text-sm);
  margin: 0;
  line-height: var(--line-height-tight);

  a {
    color: var(--color-neutral-darker);
    text-decoration: underline;
  }
}

.quiz-header {
  font-weight: 600;
  color: var(--color-neutral-dark);

  @media(max-width: $breakpoint-xs) {
    color: inherit;
    font-weight: initial;
  }
}
