.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 35vh;
}

.react-pdf__Page {
  canvas {
    border: 1px solid var(--neutral-layer-1-border);
  }
}

.react-pdf__Page-Controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--color-neutral-lighter);

  @media(max-width: $breakpoint-xs) {
    min-height: var(--button-height);
  }
}

.react-pdf__Pagination {
  margin: 0;
}