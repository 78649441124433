:root {
  // Avatar sizes
  --avater-size-xs: 12px;
  --avatar-size-sm: 20px;
  --avatar-size-md: 28px;
  --avatar-size-lg: 36px;
  --avatar-size-xl: 52px;

  // Avatar stack space
  --avatar-stack-space: 12px;
}

.avatar-stack {
  display: flex;
  align-items: center;
  font: var(--text-sm);
  color: var(--color-neutral-dark);

  .avatar {
    overflow: hidden;
    border: var(--border-width) solid var(--color-white);
  }

  .avatar + .avatar {
    margin-left: calc(var(--avatar-stack-space) * var(--minus-one));
  }

  .label {
    font: var(--text-sm);
    color: var(--color-neutral-dark);
  }

  * + .label {
    margin-left: var(--space-sm);
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--color-primary);
  border-radius: 25em;
  color: var(--color-white);
  height: var(--avatar-size-lg);
  width: var(--avatar-size-lg);
  img {
    height: var(--avatar-size-lg);
    width: var(--avatar-size-lg);
    border-radius: 25em;
    object-fit: cover;
  }
}

.avatar--xl {
  width: var(--avatar-size-xl);
  height: var(--avatar-size-xl);
  img {
    height: var(--avatar-size-xl);
    width: var(--avatar-size-xl);
  }
}
