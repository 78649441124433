.progressbar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-primary-darker);
  border-top: var(--border-width-sm) solid var(--color-neutral-lighter);
  display: flex;
  height: var(--space-sm);

  .completed {
    position: relative;
    background-image: linear-gradient(to left, var(--color-primary-lightest) 0px, var(--color-primary-base) 10vw);
    border-right: var(--border-width-sm) solid var(--color-neutral-lighter);
    width: 25%;
  }
}

.progressbar-label {
  position: absolute;
  top: -25px;
  padding-left: var(--space-md);

  color: var(--color-primary-base);
  font-size: var(--text-sm);
  font-weight: 400;
}
