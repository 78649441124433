:root {
  --sidebar-width: 215px;
}

.sidebar {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex: 0 0 var(--sidebar-width);
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  padding: var(--space-base);
  background: var(--neutral-layer-sidebar-background);
  box-shadow: var(--neutral-border-right);
  z-index: 2;
}

.sidebar_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidebar__logo {
  width: 100%;
}

.sidebar__logo-size {
  height: var(--sidebar-logo-size);
  width: auto;
}

.sidebar-nav__wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
  margin: var(--space-xl) 0 var(--space-lg) 0;
}

.sidebar-nav__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.sidebar-nav__item {
  display: flex;
  line-height: 0;
  font-size: var(--text-sm);
  font-weight: 500;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-bottom: var(--space-sm);
  padding: var(--space-xs) var(--space-base);
  border-radius: var(--radius-sm) var(--radius-lg) var(--radius-lg) var(--radius-sm);
  text-decoration: none;
  color: var(--color-primary-700);
  transition: var(--fast);

  .material-icons {
    width: 20px;
    height: 20px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--space-sm);
  }

  @media(max-width: $breakpoint-xs) {
    padding: var(--space-md);
  }

  &:hover {
    background: hsl(
      var(--color-primary-h),
      var(--color-primary-s),
      calc(var(--color-primary-l) + (100% - var(--color-primary-l)) * 0.96)
    );
    box-shadow: inset 0px 0px 0px 1px var(--color-primary-light);
    color: var(--color-primary-dark);
  }

  &:active {
    opacity: .7;
  }
}

.sidebar-nav__item--active,
.sidebar-nav__item--active:hover {
  color: var(--color-white);
  background: var(--color-primary-darkest);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08),
              0px 1px 2px rgba(0, 0, 0, 0.08),
              0px 2px 8px hsla(var(--color-primary-h), var(--color-primary-s), 24%, .16);
}

.sidebar__icon {
  display: none;
  color: var(--color-black);

  @media(max-width: $breakpoint-sm) {
    display: flex;
    position: absolute;
    z-index: 102;
    padding: var(--space);
    margin: var(--space);
  }

  @media(max-width: $breakpoint-xs) {
    padding: var(--space-sm);
    margin-top: calc(var(--space-lg) + 1px);
  }
}

.sidebar__input {
  display: none;
}

.sidebar__icon--close {
  display: none;
  position: relative;
  color: var(--neutral-layer-sidebar-text);
  margin: 0;
}

// Makes sidebar collapsible
@media (max-width: $breakpoint-sm) {
  .sidebar {
    position: absolute;
  }

  nav[data-location="off-canvas"] {
    position: absolute;
    width: 100%;
    right: -100%;
    display: none;

    z-index: 104;
    transition: right 300ms;
  }

  .sidebar__input {
    &:checked ~ nav[data-location="off-canvas"] {
      display: initial;

      .sidebar__icon {
        display: none;
      }

      .sidebar__icon--close {
        display: block;
      }

      .sidebar__icon--open {
        display: none;
      }

      right: 0;
    }
  }

  .sidebar-nav__list > .sidebar-nav__item {
    display: none;

    &.sidebar-nav__item--more {
      display: block;
    }
  }

  .sidebar-nav__list > .sidebar-nav__item.sidebar-nav__item--more {
    display: flex;
  }
}
