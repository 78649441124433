%card {
  background-color: var(--color-white);
  border-radius: var(--radius);
  box-shadow: var(--neutral-border);
  line-height: 1.5;
  overflow: hidden;
  padding: var(--space-md);
  position: relative;
}

.card {
  @extend %card;
}

.card--subtle {
  @extend %card;

  background-color: var(--neutral-card-background);
  box-shadow: var(--neutral-border);
}

.card--no-padding {
  @extend %card;

  border: var(--border-width-sm) solid var(--neutral-card-border);
  box-shadow: none;
  padding: 0;
}

.card-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "avatar meta";
  gap: var(--space-sm);

  &.card-header--actions {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "avatar meta actions";

    @media(max-width: $breakpoint-xs) {
      grid-template-columns: 1fr auto;
      grid-template-areas: "avatar actions"
                           "meta   meta   ";
    }
  }

  .card-header__meta {
    grid-area: meta;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-xxxs);
    justify-content: center;
    align-items: center;

    @media(max-width: $breakpoint-xs) {
      gap: var(--space-xs);
    }
  }

  .card-header__title {
    color: var(--color-neutral-darker);
    font-size: var(--text-md);
    margin: 0;
    line-height: var(--line-height-tight);

    a {
      color: var(--color-neutral-darker);
      text-decoration: underline;
    }
  }

  .card-header__subtitle {
    color: var(--color-neutral-dark);
    font-size: var(--text-sm);
    line-height: var(--line-height-tight);

    .tag {
      display: inline-block;
    }

    > :not(:first-child):before {
      content: '•';
      color: var(--color-neutral-base);
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
      float: left;
      margin-top: -3px;
    }
  }

  .card-header__actions {
    grid-area: actions;
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
  }
}

.card-footer {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--space-sm);
  align-items: center;
  justify-content: space-between;

  // Hacky?
  .button_to {
    width: 100%;
  }

  @media(max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
    text-align: center;

    .btn {
      width: 100%;
      justify-content: center;
    }
  }
}

.card-footer--split {
  @extend .card-footer;

  @media(max-width: $breakpoint-xs) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.card-section {
  padding: var(--space-md);
  color: var(--color-neutral-darker);
  background-color: var(--color-white);

  &:not(:first-of-type) {
    border-top: var(--border-width-sm) solid var(--neutral-card-border);
  }

  + .card-footer {
    margin-top: 0;
  }
}

.card-section--container {
  display: grid;
  gap: var(--space-md);
}

.card-section--subtle {
  @extend .card-section;

  background-color: var(--neutral-card-background);
}

.card__meeting {
  max-width: 15rem;
  margin: 0;
  display: grid;
  gap: var(--space-xxs);
  color: var(--color-neutral-dark);
  font-size: var(--text-sm);

  .meeting-header {
    font-weight: 600;
  }
}

.container-sheet {
  @media(max-width: $breakpoint-xs) {
    width: 100%;
    top: var(--space-md);
    position: absolute;
  }
}
