.class-alert {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: auto auto;
  background-color: var(--color-white);
  border: var(--border-width-sm) solid var(--color-primary-dark);
  border-radius: var(--radius-sm);
  color: var(--color-neutral-darker);
  font-size: var(--text-sm);
  padding: var(--space-sm);
  position: relative;

  @media(max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
    gap: var(--space-base);  
  }
}

.class-alert__info {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: var(--space-sm);

  @media(max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
    text-align: center;  
  }
}

.class-alert__meta {
  display: grid;
  gap: var(--space-xxxxs);

  @media(max-width: $breakpoint-xs) {
    gap: var(--space-xxs);
  }
}

.class-alert__name {
  font-weight: 600;
}

.class-alert__date {
  color: var(--color-neutral-dark);
}
