.content-block {
  margin-top: var(--space-md);
}

.content-block__header {
  color: var(--color-neutral-dark);
  margin-bottom: var(--space-md);
  line-height: var(--line-height-tighter);
  padding-bottom: var(--space-xxs);
  border-bottom: var(--border-width) solid var(--color-primary-base);

  h2 {
    margin-bottom: 0;
    font-size: var(--text-md);
  }
}

.content-block__filename {
  word-break: break-word;
}

.content-block__video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  div[data-video] {
    visibility: hidden;
  }

  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content-block__image {
  img {
    max-height: 20rem;
    max-width: 100%;
  }
}
