table.table {
  width: 100%;

  border-collapse: collapse;

  thead {
    background: var(--neutral-layer-2-background);
  }

  th {
    padding: var(--space-md) var(--space-md);

    text-transform: uppercase;

    color: var(--neutral-layer-1-text);
    border-bottom: 1.5px solid var(--neutral-layer-1-border);

    font-size: var(--text-sm);
    font-weight: 400;

    &:first-child {
      border-top-left-radius: var(--radius);
    }

    &:last-child {
      border-top-right-radius: var(--radius);
    }
  }

  tr {
    border-bottom: 1.5px solid var(--neutral-layer-1-border);
  }

  td {
    padding: 0.75rem var(--space-md);
    color: var(--neutral-layer-2-text);
    font-size: 0.875rem;
  }
}
