.identity {
  color: var(--color-primary-700);
  text-align: left;
  padding: var(--space-xs) var(--space-base);
  border-radius: var(--radius-sm) var(--radius-lg) var(--radius-lg) var(--radius-sm);
  width: 100%;
  transition: var(--fast);

  @media(max-width: #{$breakpoint-xs}) {
    padding: var(--space-md);
  }

  &:hover {
    background: hsl(
                    var(--color-primary-h),
                    var(--color-primary-s),
                    calc(var(--color-primary-l) + (100% - var(--color-primary-l)) * 0.96)
    );
    box-shadow: inset 0px 0px 0px 1px var(--color-primary-light);
    color: var(--color-primary-dark);
    height: fit-content;
  }

  &:active {
    color: var(--color-white);
  }

  &:focus {
    color: var(--color-white);
    background-color: var(--color-primary-darkest);
    box-shadow: 0 0 0 3px var(--color-neutral-600);
  }

  font-size: var(--text-sm);
  font-weight: 500;
  list-style: none;
  margin: var(--space-sm) 0;
  margin-top: 0;
  text-decoration: none;
  height: fit-content;
}

.identity__wrapper {
  position: relative;
  z-index: 1;
  background-color: var(--color-white);
}

.identity__tray {
  background-color: var(--color-primary-700);
  border: var(--border-width-sm) solid var(--color-primary-light);
  border-radius: var(--radius);
  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--space-sm);

  font-size: var(--text-sm);
  color: var(--color-white);
  opacity: 0;
  transform: translateY(0);
  z-index: -1;

  transition: opacity 150ms;
}

// TODO add transitions here
.identity__tray--open {
  opacity: 1;
  z-index: 1;
  height: auto;
  overflow: visible;
  transform: translateY(-100%) translateY(-10px);
  background: var(--neutral-layer-sidebar-background);
}

.identity__tray--closed {
  button {
    // This was a bug discovered in development, where you could hit "nothing" and you'd sign yourself out.
    pointer-events: none;
  }
}

.identity__tray-label {
  color: var(--color-neutral-600);
  margin: var(--space-sm);

  .icon {
    font-size: var(--text-lg);
    vertical-align: sub;
    margin-bottom: calc(-1 * var(--space-xxs));
  }
}

.identity__switch {
  font-size: var(--text-sm);
  font-weight: 500;
  list-style: none;
  margin-bottom: var(--space-sm);
  border-radius: var(--radius-sm) var(--radius-lg) var(--radius-lg) var(--radius-sm);
  text-decoration: none;
  color: var(--color-primary-700);
  transition: var(--fast);
  width: 100%;
  text-align: left;
  padding: var(--space-sm);

  &:hover {
    background-color: var(--color-primary-700);
    color: var(--color-white)
  }
}

.identity__switch--disabled {
  color: var(--color-neutral-600);
  pointer-events: none;

  &:hover {
    background-color: var(--sidebar-hover-color);
    pointer-events: none;
  }
}

.identity__item {
  padding: var(--space);
  border-radius: var(--radius);
  color: var(--color-white);

  &:hover {
    background-color: var(--sidebar-hover-color);
  }

  &:active {
    background: var(--color-neutral-800);
  }
}

.sign-out {
  color: var(--color-danger-light);
}

.identity__separator {
  border-color: var(--color-neutral-700);
  width: 70%;
  margin: var(--space-xs) auto;

  &:last-of-type {
    display: none;
  }
}

.identity__info {
  display: block;
}
