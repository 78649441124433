%flash--base {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xs);
  background-color: var(--color-white);
  border: var(--border-width-sm) solid transparent;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-elevation-xs);
  max-width: 100%;
  padding: var(--space-sm);
  padding-left: calc(2 * var(--space-sm) + 24px);
  padding-right: calc(var(--space-sm) + var(--space-xxs));
  pointer-events: auto;
  position: relative;
  width: 100%;
  z-index: 1;

  &::before {
    font-family: var(--font-icons);
    font-size: var(--icon-size-md);
    line-height: var(--line-height-tightest);
    margin-right: var(--space-xs);

    position: absolute;
    left: var(--space-sm);
  }

  span,
  p {
    font-size: var(--text-sm);
    line-height: var(--line-height-loose);
    margin: 0;
    padding-top: 1px;
  }
}
.flash {
  @extend %flash--base;
  transform: translate(100px);
  opacity: 0;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;

  &.is-showing {
    transform: translate(0px);
    opacity: 1;
  }

  .flash__message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a.flash__close {
      text-decoration: none;
      margin-left: var(--space-sm);
      pointer-events: auto;
      cursor: pointer;
    }
  }

  @media(max-width: $breakpoint-xs) {
    width: max-content;
  }
}

.flash--inline {
  @extend %flash--base;

  max-width: max-content;
  box-shadow: none;

  @media(max-width: $breakpoint-xs) {
    width: 100%;
    max-width: 100%;
  }
}

.flash--full-width {
  width: 100%;
  max-width: none;
}

.flash--success,
.flash--notice {
  border-color: var(--color-green-500);
  color: var(--color-success-text);

  &::before {
    color: var(--color-green-500);
    content: 'check_circle';
  }

  &.flash--inline {
    background-color: var(--color-success-lightest);
  }
}

.flash--info {
  border-color: var(--color-neutral-500);
  color: var(--color-info-text);

  &::before {
    color: var(--color-neutral-500);
    content: 'info';
  }

  &.flash--inline {
    background-color: var(--color-neutral-75);
    border-color: var(--color-neutral-200);
  }
}

.flash--warning {
  border-color: var(--color-orange-500);
  color: var(--color-warning-text);

  &::before {
    color: var(--color-orange-500);
    content: 'warning';
  }

  &.flash--inline {
    background-color: var(--color-warning-lightest);
  }
}

.flash--danger,
.flash--alert {
  border-color: var(--color-red-600);
  color: var(--color-danger-text);

  &::before {
    color: var(--color-red-600);
    content: 'error';
  }

  &.flash--inline {
    background-color: var(--color-danger-lightest);
  }
}
